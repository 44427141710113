<template>
    <div>
        <h3>Blogs</h3>
        <v-btn
                text
                :to="{ name: 'manage-blogs-new', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-add-main"
                style="background:transparent;"
        >
            <i class="fas fa-plus-circle"></i> Add New Blog
        </v-btn>
        <v-btn
                text
                :to="{ name: 'manage-blogs-category', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-add-main"
                style="background:transparent;"
        >
            <i class="fas fa-list"></i> Blog Category
        </v-btn>
        <v-btn
                text
                :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
                outlined
                class="btn btn-lg btn-secondary-main"
                style="background:transparent;"
        >
            <i class="fas fa-arrow-left"></i> Back
        </v-btn>
        <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
        <v-app>
            <div class="row mt-2">
                <div class="col-xl-12">
                    <KTPortlet v-bind:title="''">
                        <template v-slot:body>
                            <div class="row" v-if="loading">
                                <div class="col-md-12">
                                    <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                                </div>
                            </div>

                            <div class="row" v-else>
                                <div class="col-md-12">
                                    <p>
                                        Filter
                                        <a @click="filterBy('drafts')">Inactive ({{in_active}})</a>|
                                        <a @click="filterBy('published')">Active ({{activeBlog}})</a>|
                                        <a @click="filterBy('trashed')">Trash ({{trashBlog}})</a>
                                    </p>
                                    <hr/>
                                </div>
                                <div class="col-md-6">
                                    <treeselect
                                            class="mb-3"
                                            :id="'blog_category_add'"
                                            :multiple="false"
                                            :options="blog_category"
                                            :default-expand-level="1"
                                            :sort-value-by="sortValueBy"
                                            placeholder="Choose Category"
                                            @input="getBlogs(filter)"
                                            v-model="filter.category_id"
                                            :value-consists-of="valueConsistsOf"
                                            :disable-branch-nodes="false"
                                    />
                                    <!--                                    <v-select-->
                                    <!--                                        v-model="filter.category_id"-->
                                    <!--                                        label="Filter by Category"-->
                                    <!--                                        :items="blog_category"-->
                                    <!--                                        item-text="title"-->
                                    <!--                                        item-value="id"-->
                                    <!--                                        @change="getBlogs(filter)"-->
                                    <!--                                        outlined-->
                                    <!--                                        densed-->
                                    <!--                                    ></v-select>-->
                                </div>
                                <div class="col-md-6 text-right">
                                    <v-select
                                            label="Filter by Status"
                                            v-model="filter.status"
                                            dense
                                            :items="blog_status"
                                            item-text="key"
                                            item-value="value"
                                            @change="getBlogs(filter)"
                                            outlined
                                            densed
                                    ></v-select>
                                    <a href="#" @click="resetFilter" class="pull-right">Reset Filter</a>
                                </div>
                                <div class="row" v-if="!loading && blogs && blogs.length<1">
                                    <div class="col-md-12 text-center">
                                        <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                                        <h5 class="mt-10">Looks like you have not added any data here. Trying adding
                                            some data.</h5>
                                    </div>
                                </div>
                                <b-table
                                        v-else
                                        hover
                                        responsive
                                        :items="blogs"
                                        id="my-table"
                                        :busy.sync="isBusy"
                                        :fields="fields"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                >
                                    <template v-slot:table-busy>
                                        <div class="text-center text-info my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading Blogs...</strong>
                                        </div>
                                    </template>
                                    <!-- A virtual composite column -->
                                    <template v-slot:cell(title)="data">
                                        <router-link
                                                class="kt-nav__link"
                                                :to="{ name: 'manage-blogs-edit', params: { domainname:  siteUrl, id : data.item.id } }"
                                        >
                                            <span class="kt-nav__link-text">{{data.item.title}}</span>
                                        </router-link>
                                    </template>
                                    <template v-slot:cell(category_title)="data">
                                        {{data.item.category_title}}
                                    </template>

                                    <template v-slot:cell(is_active)="data">
                                        <i
                                                v-if="data.item.is_active"
                                                class="kt-nav__link-icon flaticon2-check-mark text-success"
                                        ></i>
                                        <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                                    </template>
                                    <template v-slot:cell(updated_at)="data">
                                        <span>{{moment(data.item.updated_at ,'YYYY-MM-DD').fromNow()}}</span>
                                    </template>
                                    <template v-slot:cell(publish_date)="data">
                                        <span class="badge badge-primary">{{data.item.publish_date}}</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <div class="kt-widget__toolbar" v-if="!data.item.deleted_at">
                                            <a
                                                    href="#"
                                                    class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                                    data-toggle="dropdown"
                                            >
                                                <i class="flaticon-more-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                                                <ul class="kt-nav">
                                                    <li class="kt-nav__item">
                                                        <router-link
                                                                class="kt-nav__link"
                                                                :to="{ name: 'manage-blogs-edit', params: { domainname:  siteUrl, id : data.item.id } }"
                                                        >
                                                            <i class="kt-nav__link-icon flaticon-edit"></i>
                                                            <span class="kt-nav__link-text">Edit</span>
                                                        </router-link>
                                                    </li>

                                                    <li class="kt-nav__item">
                                                        <a
                                                                href="#"
                                                                @click.prevent="publishBlogs(data.item)"
                                                                class="kt-nav__link"
                                                        >
                                                            <i class="kt-nav__link-icon flaticon-eye"></i>
                                                            <span class="kt-nav__link-text">Publish</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a href="#" @click="subheader(data.item)" class="kt-nav__link">
                                                            <i class="kt-nav__link-icon flaticon-file-2"></i>
                                                            <span class="kt-nav__link-text">Sub Header</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a
                                                                href="#"
                                                                @click.prevent="unPublishBlogs(data.item)"
                                                                class="kt-nav__link"
                                                        >
                                                            <i class="kt-nav__link-icon flaticon-close"></i>
                                                            <span class="kt-nav__link-text">Unpublish</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a href="#" @click="cloneBlog(data.item)" class="kt-nav__link">
                                                            <i class="kt-nav__link-icon flaticon-file-2"></i>
                                                            <span class="kt-nav__link-text">Clone</span>
                                                        </a>
                                                    </li>
                                                    <li class="kt-nav__item">
                                                        <a href="#" class="kt-nav__link"
                                                           @click="deleteblog(data.item.id)">
                                                            <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                                                            <span class="kt-nav__link-text">Delete</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div v-else>
                      <span class="cursor-pointer" title="Recover " @click="recoverBlog(data.item.id)">
                        <i class="fas fa-backward"></i>
                      </span>
                                        </div>
                                    </template>
                                </b-table>
                                <b-pagination
                                        v-if="rows>0"
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                        aria-controls="my-table"
                                ></b-pagination>
                            </div>
                        </template>
                    </KTPortlet>
                </div>
            </div>
            <v-dialog scrollable v-model="blogsDialog" persistent max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Add New Blogs</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <div class="form-block">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field label="Title" v-model="blog.title" required></v-text-field>
                                        <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.blog.title.$error"
                                        >Title is required</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field label="Slug" v-model="blog.slug" required></v-text-field>
                                        <p
                                                class="text-danger font-weight-bold"
                                                v-if="$v.blog.slug.$error"
                                        >Slug is required</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
                        <v-btn
                                color="white--text v-btn theme--light elevation-2 v-size--large primary"
                                class="btn-save-popup"
                                text
                                :loading="isBusy"
                                @click.prevent="saveblog"
                        >Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog scrollable v-model="subHeaderDialog" persistent max-width="900">
                <v-card>
                    <v-card-title class="headline">Page Sub Header Option</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="3">
                                <v-switch v-model="pageSubHeader.show_sub_header" label="Show Sub Header"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-switch
                                        v-model="pageSubHeader.sub_header_background_text_inverse"
                                        label="Inverse Color"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input
                                        :rules="rules"
                                        accept="image/png, image/jpeg, image/bmp"
                                        hint="Upload background image"
                                        persistent-hint
                                        prepend-inner-icon="mdi-camera"
                                        label="Background Image"
                                        v-model="pageSubHeader.sub_header_background"
                                        outlined
                                ></v-file-input>
                                <div id="favIconPreview">
                                    <img
                                            style="max-height:200px"
                                            v-if="pageSubHeader.sub_header_background_path  "
                                            :src="pageSubHeader.sub_header_background_path.thumb"
                                    />
                                </div>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                        dense
                                        v-model="pageSubHeader.sub_header_background_size"
                                        :items="['cover','contain']"
                                        outlined
                                        label="Background Size"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                        dense
                                        v-model="pageSubHeader.sub_header_background_horizontal_position"
                                        :items="['left','center','right']"
                                        outlined
                                        label="Background Position (Horizontal)"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                        dense
                                        v-model="pageSubHeader.sub_header_background_text_position"
                                        :items="['left','center','right']"
                                        outlined
                                        label="Text Position "
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select
                                        dense
                                        v-model="pageSubHeader.sub_header_background_vertical_position"
                                        :items="['top','bottom']"
                                        outlined
                                        label="Background Position (Vertical)"
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="pageSubHeader.sub_header_background_height"
                                        label="Background Height"
                                        suffix="px"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="pageSubHeader.sub_header_background_color"
                                        label="Background Color"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-color-picker
                                        outlined
                                        dense
                                        v-model="pageSubHeader.sub_header_background_color"
                                        class="ma-2"
                                ></v-color-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeSubHeaderDialog()">Cancel</v-btn>
                        <v-btn color="green darken-1" :loading="isBusy" text @click="updateSubHeader">Update</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTPortlet from "@/views/partials/content/Portlet.vue";
    import {required} from "vuelidate/lib/validators";
    import WebsiteBlogCategory from "@/services/Websites/WebsiteBlogCategoryService";
    import WebsiteBlog from "@/services/Websites/WebsiteBlogService";
    import Treeselect from "@riophae/vue-treeselect";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";

    const WebsiteBlogCategoryService = new WebsiteBlogCategory();
    const WebsiteBlogService = new WebsiteBlog();
    export default {
        name: "website-blogs",
        components: {
            treeselect: Treeselect,
            KTPortlet
        },
        validations: {
            blog: {
                title: {required},
                slug: {required}
            }
        },
        data() {
            return {
                rules: [],
                isBusy: false,
                loading: true,
                blogsDialog: false,
                sortBy: "title",
                sortDesc: false,
                date: new Date().toISOString().substr(0, 7),
                blog_category: [],
                in_active: 0,
                activeBlog: 0,
                trashBlog: 0,
                subHeaderDialog: false,
                filter: {
                    category_id: null,
                    status: null
                },
                sortValueBy: "ORDER_SELECTED",
                valueConsistsOf: "ALL_WITH_INDETERMINATE",
                pageSubHeader: {
                    show_sub_header: 1,
                    sub_header_background_text_inverse: 1
                },
                blog_status: [
                    {value: "all", key: "ALL"},
                    {value: "published", key: "PUBLISHED"},
                    {value: "published", key: "PUBLISHED"},
                    {value: "drafts", key: "DRAFT"},
                    {value: "trashed", key: "TRASHED"}
                ],
                fields: [
                    {key: "title", sortable: true},
                    {key: "category_title", sortable: true},
                    {key: "is_active", label: "Status", sortable: true},
                    {key: "action"}
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 20,
                pageOptions: [5, 10, 15],
                blog: {
                    title: "",
                    slug: ""
                },
                blogs: []
            };
        },
        methods: {
            showDialog() {
                this.blogsDialog = true;
            },
            closeDialog() {
                this.blogsDialog = false;
            },
            subheader(item) {
                this.pageSubHeader = item;
                (this.pageSubHeader.sub_header_background = null),
                    this.openSubHeaderDialog();
            },
            deleteblog(id) {
                this.$confirm({
                    message: `Are you sure you want to permanently remove this item ?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            WebsiteBlogService.delete(this.siteUrl, id).then(res => {
                                this.$snotify.success("Blog deleted successfully");
                                this.getBlogs();
                                // this.pages = res.data.data;
                            });
                        }
                    }
                });
            },
            openSubHeaderDialog() {
                this.subHeaderDialog = true;
            },
            closeSubHeaderDialog() {
                this.subHeaderDialog = false;
            },
            cloneBlog(blog) {
                let newblog = blog;
                delete newblog.id;
                delete newblog.slug;
                delete newblog.social_share_image;
                this.$confirm({
                    message: `Are you sure you want to clone this item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            WebsiteBlogService.create(this.siteUrl, newblog).then(res => {
                                this.$snotify.success("Blog cloned");
                                this.getBlogs();
                            });
                        }
                    }
                });
            },
            savePage() {
                this.$v.$touch();
                if (!this.$v.page.$error) {
                    //call api
                    //close Dialog
                    //refesh Data
                    // success Notification
                }
            },
            updateSubHeader() {
                let fd = new FormData();
                for (var key in this.pageSubHeader) {
                    if (
                        key === "social_share_image" &&
                        (this.pageSubHeader["social_share_image"] == null ||
                            this.pageSubHeader["social_share_image"] == undefined)
                    ) {
                    }
                    if (
                        key === "sub_header_background" &&
                        (this.pageSubHeader["sub_header_background"] == null ||
                            this.pageSubHeader["sub_header_background"] == undefined)
                    ) {
                    } else {
                        fd.append(
                            key,
                            this.pageSubHeader[key] == "1" ? "1" : this.pageSubHeader[key]
                        );
                    }
                }

                WebsiteBlogService.update(this.siteUrl, this.pageSubHeader.id, fd).then(
                    res => {
                        this.closeSubHeaderDialog();
                        this.$snotify.success("Page header settings updated");
                        this.getBlogs();
                    }
                );
            },
            toggleBusy() {
                this.isBusy = !this.isBusy;
            },
            getBlogs(filter = {}) {
                this.loading = true;
                WebsiteBlogService.paginate(this.siteUrl, filter).then(res => {
                    this.loading = false;
                    // console.log(res);
                    this.blogs = res.data.blogs;
                    this.in_active = res.data.in_active;
                    this.activeBlog = res.data.active;
                    this.trashBlog = res.data.trash;
                    // this.pages = res.data.data;
                });
            },
            publishBlogs(blog) {
                this.$confirm({
                    message: `Are you sure you want to publish this blog? `,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            blog.is_active = 1;
                            delete blog.social_share_image;
                            this.loading = true;
                            WebsiteBlogService.update(this.siteUrl, blog.id, blog).then(res => {
                                this.$snotify.success("Blog Published");
                                this.getBlogs();
                            });
                        }
                    }
                });
            },
            unPublishBlogs(blog) {
                this.$confirm({
                    message: `Are you sure you want to unpublished this blog? `,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            blog.is_active = 0;
                            delete blog.social_share_image;
                            this.loading = true;
                            WebsiteBlogService.update(this.siteUrl, blog.id, blog).then(res => {
                                this.$snotify.success("Blog Unpublished");
                                this.getBlogs();
                            });
                        }
                    }
                });
            },
            getBlogCategory() {
                WebsiteBlogCategoryService.paginate(this.siteUrl).then(res => {
                    this.blog_category = res.data.data;
                    // this.pages = res.data.data;
                });
            },
            resetFilter() {
                this.filter = {
                    category_id: null,
                    status: null
                };
                this.getBlogs();
            },
            filterBy(status) {
                this.filter.status = status;
                this.getBlogs(this.filter);
            },
            recoverBlog(id) {
                this.$confirm({
                    message: `Restore Blog", "Are you sure you want to restore this blog item?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            WebsiteBlogService.recoverBlog(this.siteUrl, id)
                                .then(response => {
                                    this.$snotify.success("Blog restored");
                                    this.getBlogs();
                                })
                                .catch(error => {
                                })
                                .finally(() => (this.isBusy = false));
                        }
                    }
                });
            }
        },
        computed: {
            rows() {
                return this.blogs.length;
            },
            siteUrl() {
                return this.$route.params.domainname;
            }
        },
        mounted() {
            this.siteUrl;
            this.getBlogs();
            this.getBlogCategory();
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Manage Pages", route: "/websites"}
            ]);
        }
    };
</script>
